import React, { useEffect, useState } from "react";
import './search.css'
import { Button, Form } from "react-bootstrap";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { useHistory } from "react-router-dom";
import axios from "axios";

// import '../../assest/js/custom'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ImgUrl from '../../img/tv.jpg'
import FatFooter from "../Footer/FatFooter";
import APIUrl from "../../common/Urlcontant";

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Search = () => {
  let query = useQuery();
  const history = useHistory();
  const [list, setlist] = useState([])
  const [ActorList, setActorList] = useState([])
  const [Tvchannel, setTvchannel] = useState([])
  const [language, setlanguage] = useState('')

  const apiKey = '8c8960e929b3daeea44acef93cd6fdf5';
  const prefix = 'https://image.tmdb.org/t/p/original/'

  const getLanguage = (code) => {
    const lang = new Intl.DisplayNames(['en'], { type: 'language' });
    return lang.of(code);
  }
  const SearchHandler = (e) => {

    if (query.get('query')) {
      axios.get(APIUrl + `api/tv/search-all/?query=${query.get('query')}`).then((res) => {
        try {
          if (res) {

            const data = res.data.movies.forEach((el) => {
              el.LanguageFullName = getLanguage(el.original_language);
            })
            setlist(res.data.movies)
            setActorList(res.data.artist)
            setTvchannel(res.data.channels)




          }

        } catch (error) {

        }
      })
    }

  };

  const SearchResult = (item) => {

    history.push(`/?id=${item.id}`)


  }
  const TvSearchResult=(item)=>{

    // history.push(`/TvchannelDetail?id=${item}`)


  } 
  
  const ActorSearchResult = (item) => {
    history.push(`/ActorDetail?id=${item}`)
  }

  useEffect(() => {
    SearchHandler();
    // ActorSearchResult();
  }, [query.get('query')])


  return (
    <>
      <ContentWrapper className="single-channel-page">

        {/* <div className="video-block section-padding ">
          <Row>
            <Col md={12}>
              <h3 style={{ color: "white" }}>Featured Videos</h3>
            </Col>

            {
              list.map((item, index) => {
                return (
                  (item.poster_path &&
                    <Col xl={3} sm={6} className="mb-3" key={index}>

                      <div className="video-card" onClick={() => {SearchResult(item)}} >
                        <div className="video-card-image">
                          <a className="play-icon">
                            <FontAwesomeIcon icon={faPlayCircle} />
                          </a>
                          <a>
                            <img className="img-fluid" src={prefix + item.poster_path} />
                          </a>
                        </div>
                        <div className="video-card-body">
                          <div className="video-title">
                            <a>{item.title}</a>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                )
              })
            }
          </Row>
        </div> */}


        {/* <div class="video-block section-padding">
          <div class="row">
            <div class="col-md-12">
              <div class="main-title">
                <br></br>
                <br></br>
                <h6>Channel search results</h6>
              </div>
            </div>

            <div class="grid">
              {
                Tvchannel.map((item, index) => {
                  return ( 
                    <div key={index}>
                      <div class="video-card" onClick={() => { TvSearchResult(item.id) }}>
                        <div class="video-card-image">
                          <a ><img class="grid_item" style={{ Height: "300px" }} src={item.image?.url ? item.image?.url : ImgUrl  } alt="" /></a>
                          <div class="video-card-body">
                            <div class="video-title">
                              <a >{item.name}</a>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div> */}




        <div class="video-block section-padding">
          <div class="row">
            <div class="col-md-12">
              <div class="main-title">
                <br></br>
                <br></br>
                <h6>Movie search results</h6>
              </div>
            </div>

            <div class="grid">
              {
                list.map((item, index) => {
                  return (
                    item.poster_path &&
                    // class="col-lg-2 col-md-3 col-sm-6 mb-3 px-3"
                    <div key={index}>
                      <a href={`/?id=${item.id}`} class="video-card" onClick={() => { SearchResult(item) }}>
                        <div class="video-card-image">
                          <div className="position-relative">
                            <a class="play-icon" ><i class="fas fa-play-circle"></i></a>

                            <img class="grid_item" style={{ Height: "300px" }} src={prefix + item.poster_path} alt="" />
                            <div class="laguage">{item.LanguageFullName}</div>

                            <div class="adult">{item.adult == true ? '18+' : ''}</div>
                            <div class="time">{item.vote_average > 0 ? parseFloat(item.vote_average).toFixed(2) : ''}</div>
                            <div class="releace">{new Date(item.release_date).getFullYear()}</div>
                          </div>

                          <div class="video-card-body">
                            {/* <div class="video-title"> */}
                            <a >{item.title}</a>
                            {/* </div> */}
                          </div>
                        </div>

                      </a>
                    </div>

                  )
                })
              }
            </div>
          </div>
        </div>


        <div class="video-block section-padding">
          <div class="row">
            <div class="col-md-12">
              <div class="main-title">
                <br></br>
                <br></br>
                <h6>Actor search results</h6>
              </div>
            </div>

            <div class="grid">
              {
                ActorList.map((item, index) => {
                  return (
                    item.profile_path &&

                    <div key={index}>
                      <div class="video-card" onClick={() => { ActorSearchResult(item.id) }}>
                        <div class="video-card-image">
                          <a ><img class="grid_item" style={{ Height: "300px" }} src={prefix + item.profile_path} alt="" /></a>
                          <div class="video-card-body">
                            <div class="video-title">
                              <a >{item.name}</a>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>


        {/* <hr class="mt-0"></hr>
        <div class="video-block section-padding">
          <div class="row">
            <div class="col-md-12">
              <div class="main-title"> */}
        {/* <div class="btn-group float-right right-action">
                  <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Sort by <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                    <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                    <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                  </div>
                </div> */}
        {/* <h6>Popular Channels</h6>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 mb-3">
              <div class="channels-card">
                <div class="channels-card-image">
                  <a href="#"><img class="img-fluid" src="img/s1.png" alt="" /></a>
                  <div class="channels-card-image-btn"><button type="button" class="btn btn-outline-danger btn-sm">Subscribe <strong>1.4M</strong></button></div>
                </div>
                <div class="channels-card-body">
                  <div class="channels-title">
                    <a href="#">Channels Name</a>
                  </div>
                  <div class="channels-view">
                    382,323 subscribers
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 mb-3">
              <div class="channels-card">
                <div class="channels-card-image">
                  <a href="#"><img class="img-fluid" src="img/s2.png" alt="" /></a>
                  <div class="channels-card-image-btn"><button type="button" class="btn btn-outline-danger btn-sm">Subscribe <strong>1.4M</strong></button></div>
                </div>
                <div class="channels-card-body">
                  <div class="channels-title">
                    <a href="#">Channels Name</a>
                  </div>
                  <div class="channels-view">
                    382,323 subscribers
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 mb-3">
              <div class="channels-card">
                <div class="channels-card-image">
                  <a href="#"><img class="img-fluid" src="img/s3.png" alt="" /></a>
                  <div class="channels-card-image-btn"><button type="button" class="btn btn-outline-secondary btn-sm">Subscribed <strong>1.4M</strong></button></div>
                </div>
                <div class="channels-card-body">
                  <div class="channels-title">
                    <a href="#">Channels Name <span title="" data-placement="top" data-toggle="tooltip" data-original-title="Verified"><i class="fas fa-check-circle"></i></span></a>
                  </div>
                  <div class="channels-view">
                    382,323 subscribers
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 mb-3">
              <div class="channels-card">
                <div class="channels-card-image">
                  <a href="#"><img class="img-fluid" src="img/s4.png" alt="" /></a>
                  <div class="channels-card-image-btn"><button type="button" class="btn btn-outline-danger btn-sm">Subscribe <strong>1.4M</strong></button></div>
                </div>
                <div class="channels-card-body">
                  <div class="channels-title">
                    <a href="#">Channels Name</a>
                  </div>
                  <div class="channels-view">
                    382,323 subscribers
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <FatFooter />
      </ContentWrapper>
    </>
  )
}

export default Search;