import React, {useEffect, useRef} from "react";
const $ = window.jQuery;


const CountriesCard = ({name, href='#', coverImageSrc, pdfUrl}) => {   
    
    
    // let imgRef = null;
    // useEffect(() => {
    //     const $el = $(imgRef);
    //     $el.flipBook({
    //         pdfUrl: pdfUrl,
    //         skin: "dark",
    //         btnSize: 20,
    //         backgroundColor: "#666",
    //         zoomMin: 0.9,
    //         lightBox: true
    //     });
    // }, [])

    return (
        <>
            
                <a href="/brand" className="channel">
                    <img src={coverImageSrc} />    
                    <div>
                        <span>3</span>
                    </div>              
                </a>
            
        </>
    )

}
export default CountriesCard;

