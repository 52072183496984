import React, { useEffect, useRef } from "react";
import Movie from "./Movie";
const $ = window.jQuery;
import './SingleChannel.css'

const MovieCard = ({ name, href = '#', coverImageSrc, pdfUrl ,generId, id }) => {



    // coverImageSrc="mgz/movie2.jpg"
    // coverImageSrc="https://tvzinos.com/uploads/cache/poster_thumb_web/uploads/jpg/8d9669ebdc85474699f74fb084a208b8.jpg"
    // coverImageSrc = "mgz/movieImg.jpg"

    // let imgRef = null;
    // useEffect(() => {
    //     const $el = $(imgRef);
    //     $el.flipBook({
    //         pdfUrl: pdfUrl,
    //         skin: "dark",
    //         btnSize: 20,
    //         backgroundColor: "#666",
    //         zoomMin: 0.9,
    //         lightBox: true
    //     });
    // }, [])
    // console.log(generId);
    // /?id=${id}&genreId=${generId}

    return (
        <>

            <a  href={`/?id=${id}`}>
                <img className="img-fluid w-100  grid_item" src={coverImageSrc} style={{ borderRadius: "5px" }} />
            </a>


        {/* <div style={{display:"none"}}>

            <Movie  generid={generId} />
        </div> */}
        
        </>
    )

}
export default MovieCard;

