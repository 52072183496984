import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect, useState } from "react";
import { useQuery } from "../Search/search";
import axios from "axios";
import SingleChannel from "../SingleChannel/SingleChannel";
import SingleChannelHero from  "../SingleChannel/SingleChannelHero"
import APIUrl from "../../common/Urlcontant";

const Movie = () => {
	let query = useQuery();
	const [list, setList] = useState({})
	const [Country, setCountry] = useState('')
	const [flag, setflag] = useState('')
	const [genre, setgenre] = useState([])
	const [ genId , setgenId ] = useState(query.get('genreID'));
	const prefix = 'https://image.tmdb.org/t/p/original/'
	const id = query.get('id');
	const genreID = query.get('genreId');
	

	let LanguageFullName ='';
	const getLanguage = (code) => {
		const lang = new Intl.DisplayNames(['en'], { type: 'language' });
		return lang.of(code);
	  }

	const Moviehandler = async (e) => {
		if (query.get('id')) {
			await axios.get( APIUrl + `api/tv/get-movie/${query.get('id')}/`).then((res) => {
				try {
					if (res) {
						var data = res.data.movies
						data.production_countries.forEach((item)=>{ 
							setCountry(item.name)
							setflag(item.flag)
							// item.LanguageFullName = getLanguage(item.original_language);
						});
						const arry =[]

						
						data.genres.filter((item)=>{
							arry.push(item.name + "  ")
						});
						data.original_language = getLanguage(res.data.movies.original_language)
							// el.LanguageFullName = getLanguage(el.original_language);
						
						
						
						setgenre(arry)
						setList(res.data.movies)
						
					}

				} catch (error) {

				}
			})
		}
		

	};
	useEffect(() => {
		window.scrollTo(0, 0)
		Moviehandler()
	}, [])

	return (

		<ContentWrapper className="single-channel-page">
			{
				!id ?
					<Container fluid>
						<SingleChannelNav />
						<ChannelVideos />
					</Container>
					:
					// <img ></img>
					<SingleChannelHero
						src={list.backdrop_image ? prefix + list.backdrop_image : "https://image.tmdb.org/t/p/original/https://image.tmdb.org/t/p/original/7T1rht5BLsYHOFNMfLL7UBDrMOq.jpg"}
						title={list.original_title}
						year={new Date(list.release_date).getFullYear()}
						country={Country}
						rating={parseFloat(list.vote_average).toFixed(2)}
						language={list.original_language}
						flag={flag ? flag : "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
					}
						genre={genre}
						duration={list.runtime}
						overview={list.overview}
						id={id}
						// genreID={genreID}
					/>
			}


		</ContentWrapper>



	);
};

export default Movie;
