import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
const $ = window.jQuery;


const ActorsCard = ({ name, href = '#', coverImageSrc, pdfUrl, id }) => {

    const history = useHistory()

    // coverImageSrc = "mgz/movieImg.jpg"
    // let imgRef = null;
    // useEffect(() => {
    //     const $el = $(imgRef);
    //     $el.flipBook({
    //         pdfUrl: pdfUrl,
    //         skin: "dark",
    //         btnSize: 20,
    //         backgroundColor: "#666",
    //         zoomMin: 0.9,
    //         lightBox: true
    //     });
    // }, [])

   const  ActorDetaile = () =>{
    history.push(`/ActorDetail?id=${id}`)
   }

    return (
        <>
            {/* <div className="actor-large">
                <a href="/brand">
                    <img src={coverImageSrc} />
                    <h6 style={{ color: 'white', padding: 0, marginTop: '15px', textAlign: 'center' }}>
                        {name}
                    </h6>
                </a>
            </div> */}
            
            <a className="poster "  onClick={()=>ActorDetaile()}>
                <img className="img-fluid w-100" src={coverImageSrc} style={{ borderRadius: "5px" }} />
                <h6 style={{ color: 'white', padding: 0, marginTop: '15px', textAlign: 'center',fontSize:"15px" }}>
                        {name}
                    </h6>
            </a>
        </>
    )

}
export default ActorsCard;

