import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route  } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import Navigation from "./components/Navigation/Navigation";
import Sidebar from "./components/Sidebar/Sidebar";
import Homepage from "./components/Homepage/Homepage";
import VideoPage from "./components/VideoPage/VideoPage";


import FourZeroFour from "./components/FourZeroFour/FourZeroFour";
import Contact from "./components/Contact/Contact";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import Auth from "./components/Auth/Auth";
import Settings from "./components/Settings/Settings";

import { CustomScrollToTop } from "./components/Atomics/ScrollToTop/ScrollToTop";
import Search from "./components/Search/search";
import Movie from "./components/Movie/Movie";
import Actors from "./components/Actors/Actors";
import Countries from "./components/Countries/Countries";

import ActorDetail from "./components/Actors/ActorDetail";
import Birthday from "./components/Birthday/Birthday";
import Genres from "./components/Geners/geners";

function App() {
	const [showMenu, setShowMenu] = useState(false);
	

	useEffect(() => {
		if (showMenu) {
			document.body.classList.add("sidebar-toggled");
		} else {
			document.body.classList.remove("sidebar-toggled");
		}
	}, [showMenu]);

	

	return (
		<Router>
			<Switch>
				<Route path="/auth" component={Auth} />
				<Route path="/">
					<Content showMenu={showMenu} setShowMenu={setShowMenu} />
				</Route>
				{/* <Route path="/Movies" component={Movie} /> */}
				<Route path="*" component={FourZeroFour} />
			</Switch>
		</Router>
	);
}

function Content(props) {
	
	return (
		<>
			<div className="homepage-wrapper">
				<Navigation props={props} />
				<div id="wrapper" style={{overflow:'hidden !important'}}>
					<Sidebar props={props} />

					<Switch>
					
						<Route exact path="/">
							<Movie />
						</Route>
						<Route exact path="/Birthday">
							<Birthday />
						</Route>
						<Route exact path="/ActorDetail">
							<ActorDetail />
						</Route>
						
						<Route exact path="/Actors">
							<Actors />
						</Route>			
						
						<Route exact path="/Country">
							<Countries />
						</Route>															
					
						
						<Route exact path="/genre">
							<Genres />
						</Route>
						<Route exact path="/video-page">
							<VideoPage />
						</Route>
					

						<Route exact path="/search">
							<Search />
						</Route>
						
					
						<Route exact path="/contact">
							<Contact />
						</Route>
						<Route exact path="/subscriptions">
							<Subscriptions />
						</Route>
						<Route exact path="/settings">
							<Settings />
						</Route>
						

						<Route path="*" component={FourZeroFour} />
					</Switch>
				</div>
				<ScrollToTop
					smooth
					component={<CustomScrollToTop />}
					className="scroll-to-top outline-0"
					color="white"
				/>
			</div>
		</>
	);
}

export default App;
