import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import MovieCard from "./MovieCard";
import Paginate from "../Atomics/Paginate/Paginate";
import "./SingleChannel.css"

import FatFooter from "../Footer/FatFooter";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useQuery } from "../Search/search";
import APIUrl from "../../common/Urlcontant";

const ChannelVideos = () => {
	let query = useQuery();
	const [list, setlist] = useState([])
	const prefix = 'https://image.tmdb.org/t/p/original/'
	const apiKey = '8c8960e929b3daeea44acef93cd6fdf5';
	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	let Api = '';

	const prevCountRef = useRef();
	if (query.get('sort_by')) {

		Api = APIUrl +  `api/tv/adult-movie/?sort_by=${query.get('sort_by')}&page=${page}&size=50`

	}
	else if(query.get('genreID')){
		Api  = APIUrl + `api/tv/adult-movie/?page=${page}&size=50&genre=${query.get('genreID')}`
	}
	
	else{
		Api = APIUrl + `api/tv/adult-movie/?page=${page}&size=50`
	}

	const LoadMore = async (isReset) => {

		await axios.get(Api).then((res) => {
			try {
				setTotalRecords(res.data.count)
				if (isReset)
					setlist(res.data.results);

				else
					setlist([...list, ...res.data.results]);

			} catch (error) {

			}
		})
	}
	const Moviecard = (item) => {

		history.push(`/?id=${item.movieID}`)
		// history.push(`/Movies?id=${query.get('genreID')}`)

	}


	useEffect(() => {
		LoadMore();
	}, [page])

	useEffect(() => {
		LoadMore(true)
	}, [query.get('sort_by')])



	return (
		<>
			<div className="video-block section-padding">
				{/* <Row className="content-section"> */}
				{/* <Col md={12}>
						<SectionHeader   heading="Read 6753 Movie magazines for free!" />
					</Col> */}
					
				<div class="grid">
					{
						list.map((item) => {


							// <div className="grid_item">	
							return (
								(
									item.poster_image &&
									<MovieCard
										id={item.movieID}
										coverImageSrc={prefix + item.poster_image}
										generId={query.get('genreID')} 
									/>)


							)
							// </div>

						})


					}
				</div>
				{/* </Row> */}
			</div>
			<div style={{ textAlign: "center", paddingBottom: "10px" }}>
				{
					list.length < totalRecords ? <button className="loadmore" onClick={() => setPage((page) => page + 1)}>Load More...</button> : <h2>Please search movie name</h2>
				}

			</div>

			{/* <Paginate /> */}
			<FatFooter />
		</>
	);
};

export default ChannelVideos;
