import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import CountriesCard from "./CountriesCard";
import Paginate from "../Atomics/Paginate/Paginate";
import "./SingleChannel.css";

import FatFooter from "../Footer/FatFooter";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";

const ChannelVideos = () => {
	

	const country = async() =>{

		axios.get(APIUrl + `api/tv/country`).then((res)=>{

		})
	}


	return (
		<>
			<div className="video-block section-padding">
				<Row>
					{/* <Col >
						<SectionHeader   heading="Read 6753 Countries magazines for free!" />
					</Col> */}

					<Col style={{padding: '5px'}} className="countries">
						<CountriesCard
							name="title one"
							coverImageSrc="mgz/flag.png"
							pdfUrl={"https://content.dropboxapi.com/s/tq3wtl82yussrw3/Transport%20app%20requirements.pdf?dl=0"} 
							
						/>
					</Col>

				</Row>
			</div>
			{/* <Paginate /> */}
			<FatFooter />
		</>
	);
};

export default ChannelVideos;
