import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import APIUrl from "../../common/Urlcontant";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import './geners.css'

const Genres = () =>{

    const [List , setList] = useState([]);
    const history = useHistory();
    const GenersHandler = async ()=>{
        axios.get(APIUrl + `api/tv/genre/`).then((res)=>{
            setList(res.data.genres)
        })
    }

    const RelatedGeners=(id)=>{
        history.push(`/?genreID=${id}`)
    }
    useEffect(()=>{
        GenersHandler()
    },[])

    return(
        <>
        <ContentWrapper>
           <Container fluid>

            <div class="row">
                {
                    List.map((item)=>{
                        return(
                            <div class="col-lg-2 align-itm-center " onClick={()=>RelatedGeners(item.genreID)}>
                            <img className="box_img " src="https://imglarger.com/Images/before-after/ai-image-enlarger-1-after-2.jpg" />
                            <div className="genes_text">{item.name}</div>
                        </div>
                        )
                    })
                }

            </div>

           </Container>
        </ContentWrapper>
        </>
    )

}

export default Genres;