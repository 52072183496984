import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import VideoCard from "../Atomics/VideoCard/VideoCard";
import Paginate from "../Atomics/Paginate/Paginate";
import "./SingleChannel.css"
import SingleCard from "./SingleCard";
import FatFooter from "../Footer/FatFooter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";





import {
	faAngleRight,
	faAngleLeft,
	faCircle,
} from "@fortawesome/free-solid-svg-icons";


function SampleNextArrow(props) {
	return (
		<button
			className="custom-slick-btn custom-slick-next"
			onClick={props.onClick}
		>
			<span>
				<FontAwesomeIcon
					icon={faAngleRight}
					mask={faCircle}
					transform="shrink-7"
				/>
			</span>
		</button>
	);
}

function SamplePrevArrow(props) {
	return (
		<button
			className="custom-slick-btn custom-slick-prev"
			onClick={props.onClick}
		>
			<FontAwesomeIcon
				icon={faAngleLeft}
				mask={faCircle}
				transform="shrink-7"
			/>
		</button>
	);
}



const ChannelVideos = () => {
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 8,
		slidesToScroll: 1,
		initialSlide: 0,
		swipeToSlide: true,
		autoplay: false,
		autoplaySpeed: 2000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
	};
	return (
		<>
			<div className="flix-carousel" >	
				<Slider {...settings}>
							<div className="poster" >
								<SingleCard 
									href="/Movies"
									alt="American Murderer"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							 <div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	
						
							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div> 
							<div className="poster" >
								<SingleCard 
									href="/Movies"
									alt="American Murderer"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							 <div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	
						
							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div> 
							<div className="poster" >
								<SingleCard 
									href="/Movies"
									alt="American Murderer"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							 <div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	

							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div>	
						
							<div className="poster ">				
								<SingleCard 
									href="/Movies"
									alt="The Lost City"
									src="/mgz/actor1.jpg"
								/>
							</div> 							
					
				</Slider>
			</div>
			
			<Container fluid >
				<FatFooter />
			</Container>

			{/* <Paginate /> */}
		</>
	);
};

export default ChannelVideos;
