import "./SingleChannel.css"
import SingleCard from "./SingleCard";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import APIUrl from "../../common/Urlcontant";
import VideoPlayer from "../../common/Videoplayer";
import Actors from "../Actors/Actors";
import { useQuery } from "../Search/search";
export default function SingleChannelHero({
	src,
	title,
	year,
	country,
	rating,
	language,
	flag,
	genre,
	duration,
	overview,
	id,
	genreID
}) {
	const [trailer, settrailer] = useState('')
	const [cast, setcast] = useState([])
	const [model, setmodel] = useState(false)
	const [VideoUrl, setVideoUrl] = useState('')
	const [Gener , setGener] = useState([]);
	const history = useHistory();
	// let query = useQuery();
	console.log(genreID);

	
		// if(genreID){
		// 	 axios.get(APIUrl + `api/tv/movie/?genre=${genreID}`).then((res)=>{
		// 		setGener(res.data.results);
		// 	})
		// }
	



	const TrailerHandler = async () => {

		await axios.get(APIUrl + `api/tv/movie/${id}/videos/?type=trailer`).then((res) => {

			settrailer(res.data.videos[0]?.key);
			setmodel(true)
			setVideoUrl(`https://www.youtube.com/embed/${res.data.videos[0]?.key}`)
		})
	}

	const CoastHandler = async () => {
		await axios.get(`${APIUrl}api/tv/movie/${id}/credits/`).then((res) => {

			setcast(res.data.cast);


		})
	}
	useEffect(() => {
		CoastHandler();
	}, [])

	return (
		<>

			<div className="col-md-12">
				<div className="movie-header" style={{ backgroundImage: `url(${src})` }}>
					<div className="movie-bigtitle">
						<div className="movie-maintitle"><h2>{title}</h2></div>
						<div className="movie-smalltitle">
							<p className="movie-lefttitle">{duration} Minutes  |{year}  | {country}   | {language} </p> 
							<div className="movie-righttitle">
								<span>{genre} | {rating}/10</span> <span className="imdb">  IMDB  </span>
								<img style={{ width: '50px', height:'50px'  }} src={flag}></img>
							</div>
						</div>
					</div>
					
					{/* <div className="header-buttons">
					<button className="open-login"> <i className="far fa-heart" aria-hidden="true"></i></button>
					
					</div> */}
					
				</div>
			</div>

			<div className="col-md-12">
				<a ><button id="trailer-btn" onClick={() => TrailerHandler()} className="btn play-btn dark-mode "><span className="fa fa-bullhorn" aria-hidden="true"></span> Watch Trailer</button></a>
				<a target="_blank" href={`https://rarbggo.org/torrents.php/search/?search=${title + ' ' + year}&category=movies`}>	<button id="trailer-btn" className="btn play-btn dark-mode "><span className="fa fa-download" aria-hidden="true"></span> Download</button></a>
			</div>
			<div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Overview</div>
			</div>
			<div className="col-md-12">
				<p className="movie-setion-content">
					{overview}
				</p>
			</div>

			<div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Cast</div>
			</div>

			<div className="flix-carousel-actor">
				<div className="flix-scroll-x">
					{
						cast.map((item) => {
							return (
								<>
									{
										item.profile_image &&
										<a href={`/ActorDetail?id=${item.creditID}`} className="actor">

											<img src={item.profile_image} />


											<span>{item.original_name}</span>
											<label>{item.character}</label>
										</a>
									}
								</>
							)
						})
					}

				</div>
			</div>

			{/* <div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Similar movies</div> */}
{/* 
				<a>
				<img src="https://i.stack.imgur.com/rklJI.png" style={{ borderRadius: "5px" }} />
				</a> */}
			{/* </div> */}

			<VideoPlayer modal={model} setModal={setmodel} VideoUrl={VideoUrl} />

		</>   
	);
}
