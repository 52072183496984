import SingleCard from "./SingleCard"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";

import {
	faAngleRight,
	faAngleLeft,
	faCircle,
} from "@fortawesome/free-solid-svg-icons";


function SampleNextArrow(props) {
	return (
		// <button
		// 	className="custom-slick-btn custom-slick-next"
		// 	onClick={props.onClick}
		// >
		// 	<span>
		// 		<FontAwesomeIcon
		// 			icon={faAngleRight}
		// 			mask={faCircle}
		// 			transform="shrink-7"
		// 		/>
		// 	</span>
		// </button>
        <button className="nextbtn custom-slick-btn custom-slick-next" onClick={props.onClick}><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
	);
}

function SamplePrevArrow(props) {
	return (
		// <button
		// 	className="custom-slick-btn custom-slick-prev"
		// 	onClick={props.onClick}
		// >
		// 	<FontAwesomeIcon
		// 		icon={faAngleLeft}
		// 		mask={faCircle}
		// 		transform="shrink-7"
		// 	/>
		// </button>
        <button className="prevbtn custom-slick-btn custom-slick-prev" onClick={props.onClick}><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
	);
}

const FeaturedSlide = () =>{
    var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 10,
		slidesToScroll: 1,
		initialSlide: 0,
		swipeToSlide: true,
		autoplay: false,
		autoplaySpeed: 2000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
	};

    return (
        <Slider {...settings}>
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />	
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />	
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />	
            <SingleCard 
                href="/Movies"
                alt="American Murderer"                
            />
                                
            
        </Slider>
    )
}

export default FeaturedSlide;