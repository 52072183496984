import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect } from "react";

const Countries = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<>
			<ContentWrapper className="single-channel-page">
				{/* <SingleChannelNav /> */}
				<Container fluid >
					<ChannelVideos />
				</Container>
			</ContentWrapper>

		</>
	);
};

export default Countries;
