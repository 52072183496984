import React, { useEffect, useRef } from "react";
const $ = window.jQuery;


const HomeCard = ({ name, href = '#', coverImageSrc, pdfUrl }) => {

    // coverImageSrc="mgz/movie2.jpg"
    // coverImageSrc="https://tvzinos.com/uploads/cache/poster_thumb_web/uploads/jpg/8d9669ebdc85474699f74fb084a208b8.jpg"
    coverImageSrc = "mgz/movieImg.jpg"

    // let imgRef = null;
    // useEffect(() => {
    //     const $el = $(imgRef);
    //     $el.flipBook({
    //         pdfUrl: pdfUrl,
    //         skin: "dark",
    //         btnSize: 20,
    //         backgroundColor: "#666",
    //         zoomMin: 0.9,
    //         lightBox: true
    //     });
    // }, [])

    return (
        <>

            <a className="poster col-4 col-md-2 col-xl-2 col-xxl-2" href="/single-channel">
                <img className="img-fluid w-100" src={coverImageSrc} style={{ borderRadius: "5px" }} />
            </a>

        </>
    )

}
export default HomeCard;

