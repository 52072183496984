import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect, useState } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import APIUrl from "../../common/Urlcontant";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "../Search/search";


const Actors = () => {
	let query = useQuery();
	const history = useHistory()
	const [item, setItem] = useState([])
	const [page, setPage] = useState(1);
	const [artistCounter, setartistcounter] = useState()
	const [totalRecords, setTotalRecords] = useState(0);
	const [filtering, setfiltering] = useState('')
	let Api = '';

	if (filtering) {

		Api = APIUrl + `api/tv/adult-artist/?sort_by=${filtering}&page=${page}&size=50`
	}
	else {
		Api = APIUrl + `api/tv/adult-artist/?page=${page}&size=60`
	}


	const ActorHandler = async (isReset) => {
		await axios.get(Api).then((res) => {

			try {

				setTotalRecords(res.data.count)

				if (isReset) {

					setItem(res.data.results);
				}

				else
					setItem([...item, ...res.data.results]);


			} catch (error) {

			}

		})

	}

	const counter = async () => {
		await axios.get(APIUrl + `api/tv/count-statistic/`).then((res) => {
			setartistcounter(res.data.total_artist)
		})
	}

	useEffect(() => {
		ActorHandler()
		window.scrollTo(0, 0)
		counter();

	}, [page])

	useEffect(() => {
		ActorHandler(true)
	}, [filtering])

	return (
		<>
			<ContentWrapper className="single-channel-page">
				{/* <SingleChannelNav /> */}
				<Container fluid >
					<div className="filteractor" >
						<div>
							<h5>{artistCounter} Actors </h5>

						</div>
						<div>

							<a href="/Actors" className="btn navbtn active ">
								<i className="far fa-clock" aria-hidden="true"></i>
								 <span className="for-desktop"> Newest</span>
							</a>

							<a onClick={(e) => { setfiltering('popularity') }} className="btn navbtn active">
								<i className="fab fa-imdb" aria-hidden="true"></i>
								<span className="for-desktop"> Popularity </span>
							</a>

							<a onClick={(e) => { setfiltering('name') }} className="btn navbtn ">
								<i className="fas fa-sort-alpha-down" aria-hidden="true"></i>
								<span className="for-desktop"> Name </span>
							</a>
						</div>
					</div>


					<Row>
						{
							item.map((val) => {
								return (

									(

										val.profile_image &&
										<ChannelVideos src={val.profile_image} id={val.peopleID} name={val.name} />
									)


								)
							})
						}
					</Row>

					<div style={{ textAlign: "center", paddingBottom: "10px" }}>
						{
							item.length < totalRecords ? <button className="loadmore" onClick={() => setPage((page) => page + 1)}>Load More...</button> : <h2></h2>
						}

					</div>
				</Container>
			</ContentWrapper>

		</>
	);
};

export default Actors;
