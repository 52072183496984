import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavItem = ({ href, label, active, children }) => {
	return (
		<>
			<li className={active ? "nav-item active" : "nav-item"}>
				<a href={href} className="nav-link">
					{/* <FontAwesomeIcon icon={faIcon} fixedWidth />{" "} */}
					{label == 'Home' &&
						<i class="fas fa-home" aria-hidden="true"></i>
					}
					{label == 'Tv Channels' &&
						<i class="fas fa-desktop" aria-hidden="true"></i>
					}
					{/* {label == 'Movies' &&
						<i class="fas fa-film" aria-hidden="true"></i>
					} */}
					{/* {label == 'Tv Shows' &&
						<i class="fas fa-desktop" aria-hidden="true"></i>
					} */}
					{label == 'Genres' &&
						<i class="fas fa-filter" aria-hidden="true"></i>
					}
					{label == 'Actors' &&
						<i class="fas fa-video" aria-hidden="true"></i>
					}
					{label == 'Country' &&
						<i class="fa fa-globe" aria-hidden="true"></i>
					}
					{label == 'Birthday' &&
						<i class="fa fa-calendar" aria-hidden="true"></i>
					}
					{/* {label == 'search' &&
						<i class="fa fa-search" aria-hidden="true"></i>
					} */}
					
					
					<span>{label}</span>
				</a>
			</li>
		</>
	);
};

export default NavItem;
