import "./ActorDetail.css";
import Container from "react-bootstrap/Container";

import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useQuery } from "../Search/search";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const prefix = 'https://image.tmdb.org/t/p/original/'


const ActorDetail = () => {
    const history = useHistory()
    let query = useQuery();
    const [item, setItem] = useState({})
    const [relatedmovie, setrelatedmovie] = useState([])

    const id = query.get('id')

    const Detailes = async () => {
        await axios.get( APIUrl +  `api/tv/artist/${query.get('id')}`).then((res) => {
            setItem(res.data)
        })
    }

    const relatedHandler = async() =>{
        
        await axios.get(APIUrl + `api/tv/artist-movies/${query.get('id')}?page=1`).then((res) => {
            setrelatedmovie(res.data.results)
        })
    }

    const ArtistMovieDetailes = (movieID)=>{
            history.push(`/?id=${movieID}`)
    }

    useEffect(() => {
        Detailes();
        relatedHandler();

    }, [])


    return (
        <ContentWrapper className="single-channel-page">
            {
                id ?
                    <Container fluid className="toni_content">

                        {/* <img src={item.profile_image}></img> */}
                        {/* <p>{item.biography}</p> */}
                        {/* <p>svkjsbdlkvnsdkvnnsdklvnlkdfvnlfkvnkvfnvkdvndlvkfnvldfnvdnfvdlkn</p> */}

                        {/* <img src="https://tvzinos.com/uploads/cache/actor_thumb_web/uploads/jpg/6b4dd4836ed30f4d38284bb5f13ce004.jpg"></img> */}

                        {/* <p>svkjsbdlkvnsdkvnnsdklvnlkdfvnlfkvnkvfnvkdvndlvkfnvldfnvdnfvdlkn</p> */}



                        <div className="row">
                            <div className="col-lg-12">
                                <div className="toni_bg  position-relative" style={{ backgroundImage: `url(${item.profile_image})` }}></div>
                                <div className="d-flex align-items-end ac_content position-absolute">
                                    <div className="acotrlogo text-start ">
                                        <img className="overflow-hidden" src={item.profile_image}></img>
                                    </div>
                                    <div className="actor_con mb-3">
                                        <h3 className="text-white">{item.name}</h3>
                                        <p className="text-white">{item.known_for_department} | {item.birthday} | {item.place_of_birth}</p>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div >
                            <div >
                                <div className="tonisub_content">
                                    <h6 className="text-white">Bio</h6>
                                    <p>{item.biography ? item.biography : "No biography"}</p>
                                    <h6 className="fli_text">Filmography</h6>
                                    <div className="d-flex align-items-center flex-wrap ">
                                        <div className="row">
                                        {
                                            relatedmovie?.map((res) => {
                                                return (
                                                    res.backdrop_path &&
                                                    <a  href="#" onClick={()=>ArtistMovieDetailes(res.id)} key={res} className=" py-1 px-1 col-sm-6 col-lg-2 col-md-4">
                                                        <img  className="overflow-hidden" src={`https://image.tmdb.org/t/p/original${res.backdrop_path}`}></img>
                                                    </a>
                                                )
                                            })
                                        }
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>







                    </Container>
                    :
                    // <img ></img>
                    <h1>No artist Found</h1>
            }

            {/* <ContentWrapper className="single-channel-page">
                <Container>
                    
                        id ?
                    
                    
                    
                </Container>
            </ContentWrapper> */}
        </ContentWrapper>
    );
};

export default ActorDetail;
