import { Link, useHistory } from "react-router-dom";
import React,{ useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { VerifiedTooltip } from "../Atomics/CustomCheckTooltips/CustomCheckTooltips";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./SingleChannel.css"
import APIUrl from "../../common/Urlcontant";
import Actors from "../Actors/Actors";

function getCurrntDimensions() {
	return {
		width: window.innerWidth,
		height: window.innerHeight
	}
}
function useDimensionHook(){
	const[currentDimenstion,setCurrentDimenstion] = useState(getCurrntDimensions());

	useEffect(()=>{
		const handleResize=()=>{setCurrentDimenstion(getCurrntDimensions())}

		window.addEventListener('resize', handleResize)
		
		return ()=> window.removeEventListener('resize', handleResize)
	},[])

	return currentDimenstion;
}

export default function SingleChannelNav() {

	const{height,width}=useDimensionHook();
	const [ Counter , setcounter] = useState()
	const [ genre , setGenre] = useState([]);
	
	const isMobile = width <=768 
	const[Sort_by,setSort_by] = useState('');
	const history = useHistory();

	
	 const ShortingHandler = async (value) => {
	history.push(`/?sort_by=${value}`)
	}
	
	// const Genre = async () => {
	// 	await axios.get(APIUrl + `api/tv/genre/`).then((res)=>{
	// 		setGenre(res.data.genres)
	// 	})
	// }

	const counter = async()=>{
		await axios.get(APIUrl +`api/tv/count-statistic/`).then((res)=>{
				setcounter(res.data.tmdb_total_movies)
		})
	}
	useEffect(()=>{
		// imdbHandler();
		// Genre();
		counter();
	},[])

	return (
		<>
			<div className="content-section">
				<div className="filter-section1">
					

					<div className="d-flex align-items-center ">
					{/* <NavDropdown title="Genres" id="basic-nav-dropdown" className="btn navbtn" aria-haspopup="true" aria-expanded="false" style={{ width: 'auto', height: '38px', zIndex: 1 }}>

					{
						genre.map((item)=>{
							return(
								
								<NavDropdown.Item href="#" style={{ backgroundColor: 'black' }}>{item.name}</NavDropdown.Item>
							)
						})
					}
					</NavDropdown> */}
					<h5 style={{fontSize:"18px" , marginBottom:"0px" , paddingLeft:"10px"}}>{Counter} FREE movies</h5>


				

					</div>
					<div className="btn-group season-dropdown float-right" role="group" aria-label="Basic example">
					{/* <h5>{Counter} Movies for free</h5> */}
						
						<Link to="/"  className="btn navbtn active ">
							<i className="far fa-clock" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Newest</span>}
						</Link>
					
						<a  onClick={()=>ShortingHandler('imdb')} className="btn navbtn ">
							<i className="fab fa-imdb" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Rating</span>}
						</a>
						
						<a  onClick={()=>ShortingHandler('title')} className="btn navbtn ">
							<i className="fas fa-sort-alpha-down" aria-hidden="true"></i> 
							{!isMobile && <span className="for-desktop" > Title</span>}
						</a>
						<a onClick={()=>ShortingHandler('year')} className="btn navbtn ">
							<i className="fas fa-calendar-alt" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Year</span>}
						</a>

					</div>
				</div>
			</div>
		</>
	);
}
